import React, { useEffect } from "react";
import "./style.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useMediaQuery } from "react-responsive";

export default function AfterMovies() {
  gsap.registerPlugin(ScrollTrigger);
  const isSmall = useMediaQuery({
    query: "(max-width: 1100px)"
  });
  useEffect(() => {
    // Define the animation
    const overlay1Image = document.querySelector(".overlay1 .overlay-image");
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".overlay1", // The element that triggers the animation
        start: "top bottom", // When the top of .overlay1 hits the center of the viewport
        end: "200% bottom", // When the bottom of .overlay1 hits the center of the viewport
        scrub: true, // Enables scrubbing to make the animation smooth
      },
    });

    // Add the animation to move the overlay1 image 50px to the right
    tl.to(overlay1Image, {
      x: 480, // Move 50px to the right
      ease: "power2.out", // Linear easing
    });

    // Define the animation
    const overlay2Image = document.querySelector(".overlay2 .overlay-image2");
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".overlay2", // The element that triggers the animation
        start: "top bottom", // When the top of .overlay1 hits the center of the viewport
        end: "200% bottom", // When the bottom of .overlay1 hits the center of the viewport
        scrub: true, // Enables scrubbing to make the animation smooth
      },
    });

    // Add the animation to move the overlay1 image 50px to the right
    tl2.to(overlay2Image, {
      x: -450, // Move 50px to the right
      ease: "power2.out", // Linear easing
    });

    // Define the animation for overlay3
    const overlay3Image = document.querySelector(".overlay3 .overlay-image");
    const driftingtext = document.querySelector(".drifting-text");
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".overlay3", // The element that triggers the animation
        start: "top bottom", // When the top of .overlay3 hits the center of the viewport
        end: "bottom bottom", // When the bottom of .overlay3 hits the center of the viewport
        scrub: true, // Enables scrubbing to make the animation smooth
        // onUpdate: (self) => {
        //   // Calculate the progress of the overlay3 animation
        //   const progress = self.progress;

        //   // Calculate the position of the text based on progress
        //   const textPosition = (1 - progress) * 50; // Adjust this value as needed

        //   // Apply the position to the text element
        //   const textElement = document.querySelector(".drifting-text");
        //   textElement.style.transform = `translateX(${textPosition}%)`;
        // },
      },
    });

    // Add the animation to move the overlay3 image 50px to the right
    tl3.to(overlay3Image, {
      x: 450, // Move 50px to the right
      ease: "power2.out", // Linear easing
    });

    tl3.fromTo(driftingtext, {
      y: +400, 
      ease: "power2.out", // Linear easing
    }, {
      y: 0, 
      ease: "power2.out", // Linear easing
    });
  }, []);






  return (
    <div className="aftermovie-container">
      {isSmall ? <h1 className="aftermovie-heading"> AfterMovies </h1> : <></>}
      <a href="https://www.youtube.com/watch?v=4vi2ZTXYSAo&t=17s"><div className="video left1">
        <div className="video-wrapper">
          {isSmall ? <div className="iframe">
            <h1>2017-Mayhem For Victory</h1>
            <iframe
              width="400"
              height="200"
              src="https://www.youtube.com/embed/4vi2ZTXYSAo?si=vPDJhEWFL_Pl1q66"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>

          </div>
            :
            <div className="iframe">
              <iframe
                width="600"
                height="250"
                src="https://www.youtube.com/embed/4vi2ZTXYSAo?si=vPDJhEWFL_Pl1q66"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>

            </div>
          }



          <div className="overlay1">
            <div className="overlay-image">
              <h1>2017</h1>
              <p>Mayhem For Victory</p>
            </div>
          </div>
        </div>
      </div></a>
      <a href="https://www.youtube.com/watch?v=kUhhnMoTJPQ"><div className="video right">
        <div className="video-wrapper2">
          {isSmall ? <div className="iframe">
            <h1>2018-Chants of Cachets </h1>
            <iframe
              width="400"
              height="200"
              src="https://www.youtube.com/embed/kUhhnMoTJPQ?si=kGaOnqLvcOT9HmcH"
              title="Video 2"
              allow="autoplay"
              allowFullScreen
            ></iframe></div>
            :
            <div className="iframe">
              <iframe
                width="600"
                height="250"
                src="https://www.youtube.com/embed/kUhhnMoTJPQ?si=kGaOnqLvcOT9HmcH"
                title="Video 2"
                allow="autoplay"
                allowFullScreen
              ></iframe></div>
          }

          {/* <video src="https://youtu.be/4zWxcmtGqhc?si=bAQ8eFvfniEHKSUa" controls width="320" height="240"></video> */}

          <div className="overlay2">
            <div className="overlay-image2"><h1>2018</h1><p>Chants of Cachets</p></div>
          </div>
        </div>
      </div></a>
      <a href="https://www.youtube.com/watch?v=Rm8lM1WskBc"><div className="video left2">
        <div className="video-wrapper">
          {isSmall ? <div className="iframe">
            <h1>2021-The Phoenix's Desideratum</h1>
            <iframe
              width="400"
              height="200"
              src="https://www.youtube.com/embed/Rm8lM1WskBc?si=Xh1upXgpjb8fB--6"
              title="Video 3"
              allowFullScreen
            ></iframe>
          </div>
            :
            <div className="iframe">
              <iframe
                width="600"
                height="250"
                src="https://www.youtube.com/embed/Rm8lM1WskBc?si=Xh1upXgpjb8fB--6"
                title="Video 3"
                allowFullScreen
              ></iframe>
            </div>
          }


          <div className="overlay3">
            <div className="overlay-image"><h1>2021</h1><p>The Phoenix's Desideratum</p></div>
          </div>
        </div>
      </div>
      </a>
      <div className="drifting-text-container">
        <div className="drifting-text">AfterMovies</div>
      </div>
    </div>
  );
}
