import React from 'react'
import "./style.css"
import { NavLink } from 'react-router-dom'

function DropDown(props) {
  return (
    <div className='flex flex-col dropDown fixed'>
        <ul className='flex flex-col divide-y divide-soNavLinkd divide-[#222222]'>
            <a className='z-30 text-white text-sm font-semibold pb-2 pt-2' href="/antique" onClick={() => props.setDrop((prev) => !prev)}  >Antique</a>
            <a className='z-30 text-white text-sm font-semibold pb-2 pt-2' href="/social" onClick={() => props.setDrop((prev) => !prev)}  >Social Initiatives</a>
            <a className='z-30 text-white text-sm font-semibold pb-2 pt-2' href="/vision" onClick={() => props.setDrop((prev) => !prev)}>Vision</a>
        </ul>
    </div>
  )
}

export default DropDown