import React from 'react'
import Footer from "../Footer/index";
function vision() {
  return (
    <>
    <div>vision</div>
    <Footer/>
    </>
  )
}

export default vision