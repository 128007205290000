import React from 'react'
import "./style.css"
// import { NavLink } from 'react-router-dom'

function Resources_Dropdown(props) {
  return (
    <div className='flex flex-col dropDown_res fixed'>
        <ul className='flex flex-col divide-y divide-soad divide-[#222222]'>
            {/* <a className='z-30 text-white text-sm font-semibold pb-2 pt-2' href="/sponsors" onClick={() => props.setDrop((prev) => !prev)}>Sponsors</a>
            <a className='z-30 text-white text-sm font-semibold pb-2 pt-2' href="/teams" onClick={() => props.setDrop((prev) => !prev)}  >Teams</a> */}
            <a className='z-30 text-white text-sm font-semibold pb-2 pt-2' href="https://unosq.udghosh.org.in">UNOSQ</a>
            <a className='z-30 text-white text-sm font-semibold pb-2 pt-2' href="https://ca.udghosh.org.in">CA Portal</a>
        </ul>
    </div>
  )
}

export default Resources_Dropdown;