import React, { useEffect, useState,useRef } from 'react';
import './style.css';
import { UseAuth } from '../../context/AuthContext';
import { useScrollDirection } from '../ScrollDirection/ScrollDirection.jsx';
import { NavLink } from 'react-router-dom';


export default function Profilebar({navbarRef}) {
  const {isFetched} = UseAuth();
  const {googleSignIn} = UseAuth();
  const {googleSignOut} = UseAuth();
  const [isProfileOpen, setProfileOpen] = useState(false);
  const scrollref = useRef(null);
  const scrollDirection = useScrollDirection(scrollref);
  let isLive = 0;

  useEffect(() => {
    if (scrollDirection === 'down') {
      scrollref.current.style.transform = 'translateY(-100%)';
      navbarRef.current.style.transform = 'translateY(-40px)';
    } else {
      scrollref.current.style.transform = 'translateY(0)';
      navbarRef.current.style.transform = 'translateY(0)';
    }
  }, [scrollDirection]);  
  const HandleProfileclick = () => {
    setProfileOpen(!isProfileOpen);
  };

  const handleGoogleSignIn = async () => {
    try{
      await googleSignIn();
    } catch (error){
      console.log(error);
    }
  };
  const handleGoogleSignOut = async () => { 
    googleSignOut();
  };

  // if(isLive){
  //   let redDot=document.getElementById("live");
  //   redDot.style.display="flex";
  //   redDot.style.flexDirection="column";
  //   document.getElementById("notlive").style.display="none";
  // }
  // else{
  //   document.getElementById("live").style.display="none";
  //   let blackDot=document.getElementById("notlive");
  //   blackDot.style.display="flex";
  //   blackDot.style.flexDirection="column";
  // }

  return (
    <div className='Profilebar-body' ref={scrollref}>
      <div className='Profilebar-container'>
        <ul className='Profilebar'>
          {/* help */}
          {/* <NavLink to="/vision" className='Profilebar-item' id='live'>
            <svg xmlns="http://www.w3.org/2000/svg" height="0.6em" viewBox="0 0 512 512"><path fill="#00FFFFFF"d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>
          </NavLink> */}
          {/* <a href="" className='Profilebar-item pl-0 ml-0' id='notlive'>
            <svg xmlns="http://www.w3.org/2000/svg" height="0.6em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
          </a> */}
          <span className="Profilebar-item text-white">Live</span>
          <NavLink to="/vision" className='Profilebar-item pl-0 ml-0'>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 10 17">
              <path fill='#ffffff' d="M5.193,0C0.538,0,0,2.673,0,4.684a0.978,0.978,0,0,0,1.955,0c0-1.648.225-2.729,3.238-2.729,2.852,0,2.852,1.45,2.852,2.729a1.951,1.951,0,0,1-1.29,1.675l-0.216.083C4.8,7.108,3.809,7.531,3.809,8.472v3.5a0.978,0.978,0,1,0,1.955,0V8.887c0.4-.209,1.152-0.5,1.474-0.62l0.224-.085A3.831,3.831,0,0,0,10,4.684C10,3.526,10,0,5.193,0Z"></path>
              <path fill='#ffffff' d="M5.366,14.943H3.982a1.029,1.029,0,0,0,0,2.057H5.366A1.029,1.029,0,1,0,5.366,14.943Z"></path></svg>
          </NavLink>
          {/* Profile */}
          <a onClick={HandleProfileclick} className='Profilebar-item'>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
              <path fill='#ffffff' d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" /></svg>
          </a>
          {/* Udghosh logo */}
          <a href="/" className='Profilebar-item'>
            <img className="Profilebar-logo" src="/logo.png"></img>
          </a>
        </ul>
      </div>

      {/* Animation pending */}
      {isProfileOpen && (
        <div className='Profile-login'>
          <div className='Profile-login-cross'>
            <svg onClick={HandleProfileclick} xmlns="http://www.w3.org/2000/svg" fill='#ffffff' height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
          </div>
          <div className="Profile-login-body">
            {!isFetched &&(<div className='profile-login-item'>
              <a onClick={handleGoogleSignIn} className="profile-login-link">
                <img className="profile-login-link-img" src="https://media.contentapi.ea.com/content/dam/eacom/common/200429_global-nav/eaglobalnav-iconsignin.svg" alt="Sign In" />
                <span className="profile-login-link-text">Sign In</span></a>
            </div>)}
            {isFetched &&(<div className='profile-login-item'>
              <a onClick={handleGoogleSignOut} className="profile-login-link">
                <img className="profile-login-link-img" src="https://media.contentapi.ea.com/content/dam/eacom/common/200429_global-nav/eaglobalnav-iconsignin.svg" alt="Sign In" />
                <span className="profile-login-link-text">Sign Out</span></a>
            </div>)}
            <div className="profile-login-item">
              <a href="https://events.udghosh.org.in/" target='_blank' className="profile-login-link">
                <img className="profile-login-link-img" src="https://media.contentapi.ea.com/content/dam/eacom/common/200429_global-nav/eaglobalnav-iconregister.svg" alt="Create Account" />
                <span className="profile-login-link-text">Create Account</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
