import React, { useEffect, useState } from 'react';
import './Newabout.css';
import CountUp from 'react-countup';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import calendar from './calendar.png';
import eyes from './eyes.png';
import foots from './foots.png';
import colleges from './colleges.png';
import parti from './parti.png';
import events from './events.png';
// import Trophy from './Trophycanva';

export default function Newabout() {
        const [counterOn, setCounterOn] = useState(false);
        useEffect(() => {
                gsap.registerPlugin(ScrollTrigger);
                const anim = gsap.timeline({ paused: true });
                ScrollTrigger.create({
                        trigger: ".newabout-main-content",
                        start: "top 90%",
                        // markers: true,
                        end: "bottom 95%",
                        onEnter: () => { setCounterOn(true) },
                });

        })
        return (
                <>
                        <div className='newabout-container'>
                                <div className='newabout-back'>
                                        <div className='newabout-back-up'></div>
                                        <div className='newabout-back-down'></div>
                                </div>
                                <div className='newabout-content'>

                                        <div className='newabout-about'>
                                                {/* <div className='newabout-trophy'><Trophy /></div> */}
                                                <div className='newabout-main-content'>
                                                        <div className='newabout-written'>
                                                                <div className='newabout-heading'>ABOUT US</div>
                                                                <div className='newabout-text'>
                                                                        IIT KANPUR presents the 20th edition of UDGHOSH this year. Udghosh, being the greatest college sports' festival of India, organizes enthralling sports competitions with participation from 400+
                                                                        colleges from across India, along with marvelous entertaining events like comedy night, EDM night, Bollywood night, and various exhibitions.
                                                                </div>
                                                        </div>
                                                        <div className='newabout-all-tokens'>
                                                                <div className='newabout-token'>
                                                                        <div className='newabout-foots newabout-data-one'>
                                                                                <img src={foots} alt="" className='newabout-img' />
                                                                                <p className='about-data-name'><span>{counterOn && <CountUp start={0} end={100} duration={5} delay={0} />}K+</span><br></br> FOOTFALLS</p>
                                                                        </div>
                                                                        <div className='newabout-line'></div>
                                                                        <div className='newabout-colleges newabout-data-one'>
                                                                                <p className='about-data-name'><span>{counterOn && <CountUp start={0} end={450} duration={5} delay={0} />}+</span><br></br> COLLEGES</p>
                                                                                <img src={colleges} alt="" className='newabout-img' />
                                                                        </div>
                                                                </div>
                                                                <div className='newabout-token'>
                                                                        <div className='newabout-eyes newabout-data-one'>
                                                                                <img src={eyes} alt="" className='newabout-img' />
                                                                                <p className='about-data-name'><span>{counterOn && <CountUp start={0} end={10} duration={5} delay={0} />}M+</span> <br></br>EYEBALLS</p>
                                                                        </div>
                                                                        <div className='newabout-line'></div>
                                                                        <div className='newabout-parti newabout-data-one'>
                                                                                <p className='about-data-name'><span>{counterOn && <CountUp start={0} end={2500} duration={5} delay={0} />}+</span><br></br>PARTICIPANTS</p>
                                                                                <img src={parti} alt="" className='newabout-img' />
                                                                        </div>
                                                                </div>
                                                                <div className='newabout-token'>
                                                                        <div className='newabout-days newabout-data-one'>
                                                                                <img src={calendar} alt="" className='newabout-img' />
                                                                                <p className='about-data-name'><span>{counterOn && <CountUp start={0} end={3} duration={5} delay={0} />}</span><br></br> DAYS</p>
                                                                        </div>
                                                                        <div className='newabout-line'></div>
                                                                        <div className='newabout-events newabout-data-one'>
                                                                                <p className='about-data-name'><span>{counterOn && <CountUp start={0} end={75} duration={5} delay={0} />}+</span><br></br>EVENTS</p>
                                                                                <img src={events} alt="" className='newabout-img newabout-events-img' />
                                                                        </div>
                                                                </div>

                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                </>
        )
}
