import React, { useRef, useState } from 'react';
import gsap from 'gsap';
import './Showcase.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import showcaseOverlayTop from './showcase1.png';
import showcaseOverlayBot from './showcase2.png';
import shield from './shield.png';
import Curvedtext from './curved2txt.png'; 
import { useMediaQuery } from 'react-responsive';



export default function Showcase() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1000px)",
      });
    const sliderRef = useRef(null);
    const textRef = useRef(null);
    const [slide, setSlide] = useState(0);
    const [rotation, setRotation] = useState(50);
    const rotateRingright = async (degrees) => {
        const newRotation = rotation - degrees;
        await gsap.to(".Showcase-curvedtext", {
            rotation: newRotation,
            duration: 0.3,
            ease: "bounce.out",
        });
        setRotation(newRotation);
    };
    const rotateRingleft = async (degrees) => {
        const newRotation = rotation + degrees;
        await gsap.to(".Showcase-curvedtext", {
            rotation: newRotation,
            duration: 0.3,
            ease: "bounce.out",
        });
        setRotation(newRotation);
    };


    // next and prev buttons
    const next = () => {
        if (slide === 0) {
            rotateRingright(101.5).then(() => {
                sliderRef.current.slickNext();
            });
        }
        else if (slide === 1) {
            rotateRingright(85).then(() => {
                sliderRef.current.slickNext();
            });
        } else if (slide === 2) {
            rotateRingright(78.2).then(() => {
                sliderRef.current.slickNext();
            });
        } else if (slide === 3) {
            rotateRingright(95).then(() => {
                sliderRef.current.slickNext();
            });
        }
        if (slide === 3) {
            setSlide(0);
        }
        else {
            setSlide(slide + 1);
        }
    };
    //78
    const previous = () => {
        if (slide === 0) {
            rotateRingleft(95).then(() => {
                sliderRef.current.slickPrev();
            });
        }
        else if (slide === 3) {
            rotateRingleft(77).then(() => {
                sliderRef.current.slickPrev();
            });
        } else if (slide === 2) {
            rotateRingleft(86.5).then(() => {
                sliderRef.current.slickPrev();
            });
        } else if (slide === 1) {
            rotateRingleft(101.5).then(() => {
                sliderRef.current.slickPrev();
            });
        }
        if (slide === 0) {
            setSlide(3);
        }
        else {
            setSlide(slide - 1);
        }
    };

    const settings = {
        infinite: true,
        speed: 200,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false,
        swipe: false,
    };
    
    if (isDesktopOrLaptop) {
        settings.slidesToShow = 3;
    } else {
        settings.slidesToShow = 1;
    }

    return (
        <div className='Showcase-container'>
            <img className='Showcase-back' src='https://live.staticflickr.com/65535/53230844147_a7142f713c_b.jpg' alt='back' />
            <img className='Showcase-overlay-top' src={showcaseOverlayTop} alt='overlay1' />
            <img className='Showcase-overlay-bot' src={showcaseOverlayBot} alt='overlay2' />
            <img className='Showcase-shield' src={shield} alt='shield' />
            <img className='Showcase-curvedtext' ref={textRef} src={Curvedtext} alt='curved text' />

            <Slider className='Showcase-slider' ref={sliderRef}{...settings}>

                <div>
                    <img
                        alt=""
                        key="1"
                        src="https://live.staticflickr.com/65535/53232266585_f45e55a515_b.jpg"
                    />
                </div>
                <div>
                    <img
                        alt=""
                        key="2"
                        src="https://live.staticflickr.com/65535/53232076348_b9bd4d72b2_b.jpg"
                    />
                </div>
                <div>
                    <img
                        alt=""
                        key="3"
                        src="https://live.staticflickr.com/65535/53231767581_2a9316d6ea_b.jpg"
                    />
                </div>
                <div>
                    <img
                        alt=""
                        key="4"
                        src="https://live.staticflickr.com/65535/53232266535_0e4a60861a_b.jpg"
                    />
                </div>
                
            </Slider>
            <button id="btn-left" className="showcase-carousel-btn" onClick={previous}>
                <svg width="23" height="37" viewBox="0 0 23 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 33.4601L5 18.2301L20 2.99998" stroke="black" strokeWidth="6" strokeLinecap="round" />
                </svg>


            </button>
            <button id="btn-right" className="showcase-carousel-btn" onClick={next}>
                <svg width="23" height="36" viewBox="0 0 23 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 3L18 18L3 33" stroke="black" strokeWidth="6" strokeLinecap="round" />
                </svg>

            </button>

        </div>
    );

}



