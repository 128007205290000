import React from 'react';
import './other.css';
import gallery from './gallery.png';
import competi from './competitions.jpg';
import esports from './esports.jpg';
import ca from './ca.jpg';
import unosq from './unosq.jpg';

export default function other() {
  return (
    <>
        <div className='other-container'>
            <div className='other-upper'>
                <div className='other-gallery other-element'>
                    <a href='/gallery'>
                      <img src={gallery} alt="" className='other-gallery-img other-img'/>
                      <p className='other-gallery-title other-title'>GALLERY</p>
                    </a>
                </div>
                <div className='other-competi other-element'>
                  <a href="https://events.udghosh.org.in/">
                    <img src={competi} alt="" className='other-img'/>
                    <p className='other-title'>COMPETITIONS</p>
                  </a>
                  
                </div>
                <div className='other-social other-element'>
                  <a href="/social">
                    <img src="https://live.staticflickr.com/65535/53229943776_673197fc2d_h.jpg" alt="" className='other-img'/>
                    <p className='other-title'>SOCIAL INITIATIVES</p>
                  </a>
                  
                </div>
            </div>
            <div className='other-lower'>
                <div className='other-esports other-element'>
                  <a href="https://esports.udghosh.org.in/">
                    <img src={esports} alt="" className='other-img'/>
                    <p className='other-title'>E-SPORTS</p>
                  </a>
                  
                </div>
                <div className='other-ca other-element'>
                  <a href="https://ca.udghosh.org.in/">
                    <img src={ca} alt="" className='other-img'/>
                    <p className='other-title'>CA</p>
                  </a>
                  
                </div>
                <div className='other-unosq other-element'>
                  <a href="https://unosq.udghosh.org.in/">
                    <img src={unosq} alt="" className='other-img'/>
                    <p className='other-title'>UNOSQ</p>
                  </a>
                  
                </div>
            </div>
        </div>
    </>
  )
}
