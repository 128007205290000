import React from "react";
import "./Homepage.css";
import Header from "../Header/Header";
import Showcase from "../Showcase/Showcase.jsx";
import Other from "../other_websites/other";
// import Newabout from '../Newabout/Newabout';
import AfterMovies from "../Aftermovies/Aftermovies";
import Footer from "../Footer/index";
import Newabout from "../Newabout/Newabout";


export default function Homepage() {
  return (
    <>
      <Header />
      <Newabout/>
      <Showcase />
      <Other />
      {/* <About /> */}
      <AfterMovies />
      <Footer/>
    </>
  );
}
