import "./App.css";
import { useRef } from "react";
import Navbar from "./components/navbar/Navbar.jsx";
import Profilebar from "./components/Profilebar/Profilebar";
import { AuthContextProvider } from "./context/AuthContext";
import { Route, Routes } from "react-router-dom";
import Homepage from "./components/Homepage/Homepage";
import Sponsors from "./components/Sponsors/Sponsors";
import Teams from "./Pages/teams/Teams.jsx";
import C_Antique from "./components/Commitments/antique";
import C_Impact from "./components/Commitments/impacts";
import C_Initiatives from "./components/Commitments/initiatives";
import C_Vision from "./components/Commitments/vision";
import Gallery from "./components/Gallery";

import Social from "./components/social/social";
import Vision from "./components/vision/vision";

import Antique from "./components/antique/antique";
function App() {
  const navbarRef = useRef();
  return (
    <>
      <AuthContextProvider>
        <div className="App">
          <Profilebar navbarRef={navbarRef} />
          <Navbar navbarRef={navbarRef} />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/sponsors" element={<Sponsors />} />
            <Route path="/social" element={<Social />} />
            <Route path="/vision" element={<Vision />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/commitments/antique" element={<C_Antique />} />
            <Route path="/commitments/impacts" element={<C_Impact />} />
            <Route
              path="/commitments/initiatives"
              element={<C_Initiatives />}
            />
            <Route path="/commitments/vision" element={<C_Vision />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/antique" element={<Antique />} />
          </Routes>
          {/* <Footer /> */}
          {/* Footer removed as individually added in each page because of some issue. */}
        </div>
      </AuthContextProvider>
    </>
  );
}

export default App;
