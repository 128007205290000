import React from 'react';
import './Teams.css';
import { Link } from 'react-scroll';
import teamsData from './teams.json';
import { useEffect } from 'react';

export default function Teams() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className='teams-container pb-32'>
        <div className='teams-list'>
          <div className='teams-filled-half'></div>
          <div className='teams-mid-arc'></div>
          <div className='teams-out-arc'></div>
          <div className='teams-verti'>
            {teamsData.teams.map((team, index) => (
              <div key={index} className={`teams-${team.id.split('-')[2]} teams-headings`}>
                <Link style={{ cursor: "pointer" }} activeClass="active" to={team.id} smooth={true} duration={500} spy={true} offset={-105}>
                  {team.title}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className='teams-content'>
          <div className='teams-header'>TEAMS</div>
          {teamsData.teams.map((team, index) => (
            <div key={index} className='teams-about' id={team.id}>
              <div className='teams-title'>{team.title}</div>
              <div className='teams-all-photos'>
                {team.members.map((member, memberIndex) => (
                  <div key={memberIndex} className='teams-photos'>
                    <img src={member.photo} alt="" className='teams-head-photo' />
                    <p className='teams-firstname'>{member.name}</p>
                    <div className='teams-socials'>
                      <div className='teams-heads-socials'>
                        <a href={member.linkedin} target="_blank" rel="noopener noreferrer"><i className='fab fa-linkedin icon'></i></a>
                        <a href={`tel:${member.phone}`} target="_blank" rel="noopener noreferrer"><i className='fa fa-phone'></i></a>
                        <a href={`mailto:${member.email}`} target="_blank" rel="noopener noreferrer"><i className='fa fa-envelope'></i></a>
                      </div>
                      <div className='teams-socials-line'></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
