import React from 'react'
import Footer from "../Footer/index";
function impacts() {
  return (
    <>
    <div>impacts</div>
    <Footer/>
    </>
  )
}

export default impacts