import React from 'react'
import Footer from "../Footer/index";
function initiatives() {
  return (
    <>
    <div>initiatives</div>
    <Footer/>
    </>
  )
}

export default initiatives