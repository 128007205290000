import React from 'react';
import './Header.css';
import { useEffect } from 'react';


export default function Header() {
    //  For background video
    const videoplay = () => {
        const video = document.getElementById('background-video');
        video.play();
    };
    return (
        <>
        <section className="main-header-container">
            {/* <div className='main-header-container'> */}
                {/* Background video */}
                <video src="/mainbackground.mp4" onLoad={videoplay}  id='background-video' autoPlay loop muted />
                <div className='Ca-prog-bod'>
                    <div className='Ca-prog-reg'>
                        <p>CA Programs are live, Steal the chance to represent your college. </p>
                        <a href='https://ca.udghosh.org.in/' >Register here!</a>
                    </div>
                </div>
                <div className='main-header-title'>
                    <img src="/typeface.png" alt="udghosh logo" className="udghosh-header-logo" />
                </div>
                <div className='main-header-content'>
                    <p>Play the very best of Football, Cricket, Volley Ball, Basketball, and twenty different games to showcase your performance and become one of the colloseum's conquest. Enjoy the festivals diverse culture like Pronite, Zumba, EDM nights, Udaan and much more.</p>
                </div>

            {/* </div> */}
        </section>

        </>
    )
}
