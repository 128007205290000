import React from 'react'
import Footer from "../Footer/index";
function antique() {
  return (
    <>
    <div>antique</div>
    <Footer/>
    </>
  )
}

export default antique